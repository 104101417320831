.header-link {
  @apply font-semibold uppercase text-sm;

  &:hover {
    @apply text-primary;
  }
}

.account-action-link {
  @apply text-white text-xs uppercase font-semibold leading-none;

  &:hover {
    @apply opacity-75;
  }
}

.main-menu-toggle {
  display: block;
  height: 20px;
  width: 24px;

  & span {
    height: 3px;
    left: 50%;
    margin-left: -12px;
    margin-top: -1.5px;
    top: 50%;
    transition: 0.25s ease;
    width: 24px;

    &:nth-child(1) {
      transform: translateY(-8px);
    }

    &:nth-child(3) {
      transform: translateY(8px);
    }
  }

  &.active {
    & span {
      &:nth-child(1) {
        transform: translateY(0) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
}

.main-menu {
  transform: translateY(82px);
}

.main-menu-link {
  @apply block pb-4 font-semibold border-b border-gray-800 text-white relative pr-6;

  &:hover {
    @apply border-primary;
  }
}

.main-menu-overlay {
  @apply bg-black opacity-50 fixed inset-0 h-full w-full z-10 hidden;

  &.active {
    @apply block cursor-pointer;
  }
}

.submenu {
  display: none;
}

.submenu.open {
  display: block;
}