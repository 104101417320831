@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~@glidejs/glide/src/assets/sass/glide.core.scss';
@import 'variables';

.banner-home {
  background-image: url('../images/banner.png');
}

.banner-about {
  background-image: url('../images/banner-about.png');
}

.consultancy-cost {
  bottom: -0.5rem;
  left: -4rem;

  @screen lg {
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.about-image {
  background-image: url('../images/safety-men-hivis.png');
}

.vision-image {
  background-image: url('../images/industrial-workers.png');
}

.training-section {
  background: linear-gradient(to bottom, $white 49%, $light-gray 50%, $light-gray 100%);
}

.training-image {
  background-image: url('../images/training-image.png');

  &:before {
    @apply absolute inset-0 w-full h-full;
    background: rgba($black, 0.5);
    content: '';
  }
}

.banner {
  @apply bg-black bg-no-repeat bg-cover bg-center bg-fixed py-16 relative;

  @screen lg {
    @apply py-32;
  }

  &:before {
    @apply absolute inset-0 w-full h-full;
    background: linear-gradient(to right, rgba($black, 0.85) 0%, transparent 100%);
    content: '';
  }
}

.glide__slide {
  height: auto !important;
}

.clients-slider {
  &__slide {
    @apply h-full items-center flex-col;
    display: flex !important;
  }
}

@import 'breadcrumb';
@import 'buttons';
@import 'course';
@import 'flash';
@import 'footer';
@import 'form';
@import 'header';
@import 'news';
@import 'pagination';
@import 'review';
@import 'subtitle';
@import 'autocomplete';
@import 'custom';

.ipaf-radio:checked + label {
  @apply border-2 border-primary;
}
