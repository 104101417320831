button {
  @apply cursor-pointer;
}

.btn {
  @apply px-6 py-4 uppercase text-sm leading-none inline-block text-center font-semibold;
}

.btn-ghost {
  @apply px-6 py-4 uppercase text-sm leading-none inline-block text-center font-semibold border;
}

.btn-ghost-primary {
  @apply border-primary text-primary;

  &:hover {
    @apply bg-primary text-white;
  }
}

.btn-ghost-white {
  @apply border-white text-white;

  &:hover {
    @apply bg-white text-primary;
  }
}

.btn-primary {
  @apply bg-primary text-white;

  &:hover {
    @apply bg-primary-dark;
  }
}

.btn-black {
  @apply bg-black text-white;

  &:hover {
    @apply bg-gray-900;
  }
}

.btn-green {
  @apply bg-green-600 text-white;

  &:hover {
    @apply bg-green-500;
  }
}

.btn-rounded {
  @apply rounded;
}

.news-filter-button {
  @apply inline-block font-semibold border-2 border-white py-2 px-4 rounded leading-none;

  &:hover {
    @apply border-primary text-primary;
  }
}

.news-filter-button-active {
  @apply border-primary text-primary;
}
