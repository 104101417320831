.news-card {
  @apply bg-white overflow-hidden border-b-4 border-primary flex justify-between flex-col h-full;

  &:hover {
    @apply shadow-lg;
  }

  &__subheading {
    @apply text-primary text-xs mb-1 leading-none font-semibold;
  }

  &__heading {
    @apply font-semibold mb-2 leading-tight;

    @screen lg {
      @apply text-xl;
    }
  }

  &__date {
    @apply text-sm flex items-center;
  }
}

.news-date {
  &__icon {
    @apply opacity-75 mr-2;
  }

  &__text {
    @apply leading-none;
  }
}
