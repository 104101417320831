.breadcrumb {
  @apply py-4 text-xs;

  @screen md {
    @apply text-sm;
  }

  ul {
    i,
    svg {
      @apply mx-1;
    }

    a {
      @apply underline;

      &:hover {
        @apply no-underline;
      }
    }
  }
}
