@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.banner-home {
  background-image: url("../images/banner.png");
}

.banner-about {
  background-image: url("../images/banner-about.png");
}

.consultancy-cost {
  bottom: -0.5rem;
  left: -4rem;
}
@screen lg {
  .consultancy-cost {
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.about-image {
  background-image: url("../images/safety-men-hivis.png");
}

.vision-image {
  background-image: url("../images/industrial-workers.png");
}

.training-section {
  background: linear-gradient(to bottom, #fff 49%, #f7fafc 50%, #f7fafc 100%);
}

.training-image {
  background-image: url("../images/training-image.png");
}
.training-image:before {
  @apply absolute inset-0 w-full h-full;
  background: rgba(0, 0, 0, 0.5);
  content: "";
}

.banner {
  @apply bg-black bg-no-repeat bg-cover bg-center bg-fixed py-16 relative;
}
@screen lg {
  .banner {
    @apply py-32;
  }
}
.banner:before {
  @apply absolute inset-0 w-full h-full;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.85) 0%, transparent 100%);
  content: "";
}

.glide__slide {
  height: auto !important;
}

.clients-slider__slide {
  @apply h-full items-center flex-col;
  display: flex !important;
}

.breadcrumb {
  @apply py-4 text-xs;
}
@screen md {
  .breadcrumb {
    @apply text-sm;
  }
}
.breadcrumb ul i,
.breadcrumb ul svg {
  @apply mx-1;
}
.breadcrumb ul a {
  @apply underline;
}
.breadcrumb ul a:hover {
  @apply no-underline;
}

button {
  @apply cursor-pointer;
}

.btn {
  @apply px-6 py-4 uppercase text-sm leading-none inline-block text-center font-semibold;
}

.btn-ghost {
  @apply px-6 py-4 uppercase text-sm leading-none inline-block text-center font-semibold border;
}

.btn-ghost-primary {
  @apply border-primary text-primary;
}
.btn-ghost-primary:hover {
  @apply bg-primary text-white;
}

.btn-ghost-white {
  @apply border-white text-white;
}
.btn-ghost-white:hover {
  @apply bg-white text-primary;
}

.btn-primary {
  @apply bg-primary text-white;
}
.btn-primary:hover {
  @apply bg-primary-dark;
}

.btn-black {
  @apply bg-black text-white;
}
.btn-black:hover {
  @apply bg-gray-900;
}

.btn-green {
  @apply bg-green-600 text-white;
}
.btn-green:hover {
  @apply bg-green-500;
}

.btn-rounded {
  @apply rounded;
}

.news-filter-button {
  @apply inline-block font-semibold border-2 border-white py-2 px-4 rounded leading-none;
}
.news-filter-button:hover {
  @apply border-primary text-primary;
}

.news-filter-button-active {
  @apply border-primary text-primary;
}

.course-card {
  @apply bg-white overflow-hidden border-b-4 border-primary flex justify-end flex-col h-full;
}
.course-card:hover {
  @apply shadow-lg;
}
.course-card__subheading {
  @apply text-primary text-xs mb-1 leading-none font-semibold;
}
.course-card__heading {
  @apply font-semibold leading-tight;
}
@screen lg {
  .course-card__heading {
    @apply text-xl;
  }
}
.course-card__date {
  @apply text-sm flex items-center mt-2;
}

.course-date__icon {
  @apply opacity-75 mr-2;
}
.course-date__text {
  @apply leading-none;
}

.course-slider {
  @apply relative;
}
.course-slider:before {
  @apply h-16 -mt-16 absolute w-full top-0 left-0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, transparent 100%);
  content: "";
}
.course-slider__controls {
  top: 50%;
  transform: translateY(-50%);
}

.course-list-link {
  @apply pb-4 font-semibold border-b border-black text-black relative pr-6 flex justify-between;
}
.course-list-link:hover {
  @apply border-primary text-primary;
}

.flash-notice {
  @apply bg-blue-100 border-t-4 border-blue-500 text-blue-900 py-6;
}

.flash-alert {
  @apply bg-red-100 border-t-4 border-red-500 text-red-900 py-6;
}

.footer-link {
  @apply text-white;
}
.footer-link:hover {
  @apply opacity-75;
}

.form-label {
  @apply block text-gray-700 text-sm font-semibold mb-2;
}

.form-input {
  @apply bg-white appearance-none border border-gray-700 rounded w-full p-4 leading-tight;
}
.form-input:hover {
  @apply border-gray-900 shadow-md;
}
.form-input--readonly {
  @apply bg-gray-200 cursor-not-allowed;
}

.form-input-wrapper {
  @apply mb-4;
}
.form-input-wrapper .error {
  @apply block text-sm text-red-700 mt-2;
}

.form-checkbox-label {
  @apply text-gray-700 text-sm font-semibold;
}

.form-checkbox-input {
  @apply leading-tight mr-2;
}

.form-side-block {
  @apply w-1/2 h-auto bg-primary bg-cover bg-no-repeat bg-center hidden;
}
@screen lg {
  .form-side-block {
    @apply block;
  }
}

.form-input-categories {
  @apply -mb-4;
}
.form-input-categories .checkbox {
  @apply inline-block mr-4 mb-4;
}
.form-input-categories .checkbox input {
  @apply mr-2;
}
.form-input-categories .checkbox label {
  @apply border-2 px-4 py-2 rounded flex items-center;
}
.form-input-categories .checkbox label:hover {
  @apply border-gray-900 shadow-md;
}

.form-input-switch {
  @apply relative w-16 h-8 mb-4;
}
.form-input-switch .form-input-wrapper {
  @apply absolute inset-auto w-full h-full mb-0;
}

.form-input-switch-input {
  @apply hidden;
}

.form-label-switch {
  @apply absolute inset-auto w-full h-full bg-gray-200 rounded-full;
  font-size: 0;
}

.form-input-switch-status {
  @apply rounded-full w-6 h-6 absolute bg-gray-500 pointer-events-none ml-2;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.25s ease;
}
.form-input-switch-status[data-css-class=true] {
  @apply bg-green-500 -ml-8;
  left: 100%;
}
.form-input-switch-status[data-css-class=false] {
  @apply bg-gray-500 ml-2;
  left: 0;
}

input:not(:placeholder-shown) + .form-search-icon,
input:not(:placeholder-shown):valid:focus + .form-search-icon,
input:not(:placeholder-shown):valid:hover + .form-search-icon {
  display: none;
}

input:not(:placeholder-shown):valid + .form-search-icon {
  display: block;
}

.field_with_errors .form-input {
  @apply border-red-700;
}

.form-search-icon {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.header-link {
  @apply font-semibold uppercase text-sm;
}
.header-link:hover {
  @apply text-primary;
}

.account-action-link {
  @apply text-white text-xs uppercase font-semibold leading-none;
}
.account-action-link:hover {
  @apply opacity-75;
}

.main-menu-toggle {
  display: block;
  height: 20px;
  width: 24px;
}
.main-menu-toggle span {
  height: 3px;
  left: 50%;
  margin-left: -12px;
  margin-top: -1.5px;
  top: 50%;
  transition: 0.25s ease;
  width: 24px;
}
.main-menu-toggle span:nth-child(1) {
  transform: translateY(-8px);
}
.main-menu-toggle span:nth-child(3) {
  transform: translateY(8px);
}
.main-menu-toggle.active span:nth-child(1) {
  transform: translateY(0) rotate(45deg);
}
.main-menu-toggle.active span:nth-child(2) {
  opacity: 0;
}
.main-menu-toggle.active span:nth-child(3) {
  transform: translateY(0) rotate(-45deg);
}

.main-menu {
  transform: translateY(82px);
}

.main-menu-link {
  @apply block pb-4 font-semibold border-b border-gray-800 text-white relative pr-6;
}
.main-menu-link:hover {
  @apply border-primary;
}

.main-menu-overlay {
  @apply bg-black opacity-50 fixed inset-0 h-full w-full z-10 hidden;
}
.main-menu-overlay.active {
  @apply block cursor-pointer;
}

.submenu {
  display: none;
}

.submenu.open {
  display: block;
}

.news-card {
  @apply bg-white overflow-hidden border-b-4 border-primary flex justify-between flex-col h-full;
}
.news-card:hover {
  @apply shadow-lg;
}
.news-card__subheading {
  @apply text-primary text-xs mb-1 leading-none font-semibold;
}
.news-card__heading {
  @apply font-semibold mb-2 leading-tight;
}
@screen lg {
  .news-card__heading {
    @apply text-xl;
  }
}
.news-card__date {
  @apply text-sm flex items-center;
}

.news-date__icon {
  @apply opacity-75 mr-2;
}
.news-date__text {
  @apply leading-none;
}

.pagy-nav {
  @apply flex items-center justify-center mt-8 -mx-1;
}
.pagy-nav .page {
  @apply w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mx-1 text-sm;
}
.pagy-nav .page.active {
  @apply bg-primary text-white;
}
.pagy-nav .page:hover {
  @apply opacity-75;
}

.pagination-button {
  @apply w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mx-1;
}
.pagination-button:hover {
  @apply opacity-75;
}

.pagination-button-active {
  @apply w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center mx-1;
}
.pagination-button-active:hover {
  @apply opacity-75;
}

.courses-calendar-pagination .page {
  @apply bg-gray-800 text-white;
}

.review-card {
  @apply border border-gray-300 bg-white px-6 py-4 justify-between flex flex-col rounded;
}
@screen lg {
  .review-card {
    @apply px-12 py-8;
  }
}
.review-card__quote {
  @apply mb-4;
}
@screen lg {
  .review-card__quote {
    @apply mb-8;
  }
}
.review-card__author {
  @apply flex items-center;
}

.review-author__avatar {
  @apply w-10 h-10 rounded-full mr-2;
}
.review-author__name {
  @apply text-gray-800 font-semibold uppercase;
}
.review-author__company {
  @apply text-gray-700 text-sm uppercase;
}

.subtitle {
  @apply text-sm mb-1 leading-none uppercase;
}

.subtitle-primary {
  @apply text-primary;
}

.subtitle-white {
  @apply text-white;
}

.autocomplete__menu {
  @apply w-full overflow-y-scroll absolute rounded mt-2 border border-gray-400;
  max-height: 300px;
  top: 100%;
}
.autocomplete__menu--hidden {
  @apply hidden;
}
.autocomplete__menu--visible {
  @apply block;
}
.autocomplete__menu .autocomplete__option {
  @apply bg-white p-2;
}
.autocomplete__menu .autocomplete__option:hover {
  @apply text-primary;
}
.autocomplete__menu .autocomplete__option:focus, .autocomplete__menu .autocomplete__option:active {
  @apply outline-none border-2 border-black;
}
.autocomplete__menu .autocomplete__option--odd {
  @apply bg-gray-300;
}

.course-form .autocomplete__wrapper .autocomplete__input {
  @apply border border-gray-700 bg-white rounded appearance-none leading-none p-4 text-sm w-full;
}
.course-form .autocomplete__wrapper .autocomplete__input:hover {
  @apply border-primary;
}

.home-form .search-wrapper {
  @apply flex w-full;
}
.home-form .autocomplete__wrapper {
  @apply flex w-full;
}
.home-form .autocomplete__wrapper .autocomplete__input {
  @apply bg-white rounded-l appearance-none leading-none p-4 text-sm w-full;
}
.home-form .autocomplete__wrapper .autocomplete__input:hover {
  @apply bg-gray-200;
}

.autocomplete-results {
  @apply mt-2 absolute max-h-64 overflow-y-scroll shadow-xl rounded;
}

.autocomplete-nav {
  @apply bg-white border border-gray-200 rounded;
}

.autocomplete-link {
  @apply w-full p-3 relative text-left text-sm;
}
.autocomplete-link:focus {
  @apply outline-none z-10 shadow-outline text-primary bg-gray-100;
}
.autocomplete-link:hover {
  @apply text-primary bg-gray-100;
}
.autocomplete-link:not(:first-of-type) {
  @apply border-t border-gray-200;
}
.autocomplete-link:last-of-type {
  @apply rounded-b;
}
.autocomplete-link small {
  @apply block text-xs text-gray-800 mt-1;
}

.autocomplete-input {
  @apply border-r-0 rounded-r-none;
}
.autocomplete-input:focus {
  @apply outline-none shadow-outline bg-gray-100;
  outline-offset: 0;
}

.autocomplete-button {
  @apply appearance-none bg-primary text-white py-4 px-6 rounded-r;
}
.autocomplete-button:hover {
  @apply bg-primary-dark;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.trix-content-display {
  @apply text-gray-700;
}
.trix-content-display h2 {
  @apply text-xl font-semibold text-black;
}
@screen lg {
  .trix-content-display h2 {
    @apply text-2xl;
  }
}
.trix-content-display code,
.trix-content-display pre {
  @apply bg-gray-200 px-4 py-2 rounded font-mono text-sm;
}
.trix-content-display blockquote {
  @apply border-l-4 border-gray-300 pl-4;
}
.trix-content-display a {
  @apply text-primary underline;
}
.trix-content-display a:hover {
  @apply opacity-75;
}
.trix-content-display ol {
  @apply list-decimal ml-4;
}
.trix-content-display ul {
  @apply list-none flex -mb-6 flex-wrap;
}
@screen sm {
  .trix-content-display ul {
    @apply -mx-4;
  }
}
.trix-content-display ul li {
  @apply mb-6 text-gray-700 flex items-start;
}
@screen sm {
  .trix-content-display ul li {
    @apply w-1/2 px-4;
  }
}
.trix-content-display ul li svg {
  @apply text-primary text-xl mr-4 leading-none;
  margin-top: 4px;
}
.trix-content-display ul li:before {
  @apply hidden;
  content: "\f14a";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

.error_notification {
  @apply mb-4 bg-red-100 border-t-4 border-red-500 text-red-900 p-6;
}

.ipaf-radio:checked + label {
  @apply border-2 border-primary;
}