.pagy-nav {
  @apply flex items-center justify-center mt-8 -mx-1;

  .page {
    @apply w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mx-1 text-sm;

    &.active {
      @apply bg-primary text-white;
    }

    &:hover {
      @apply opacity-75;
    }
  }
}

.pagination-button {
  @apply w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mx-1;

  &:hover {
    @apply opacity-75;
  }
}

.pagination-button-active {
  @apply w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center mx-1;

  &:hover {
    @apply opacity-75;
  }
}

.courses-calendar-pagination {
  .page {
    @apply bg-gray-800 text-white;
  }
}
