.trix-content-display {
  @apply text-gray-700;

  h2 {
    @apply text-xl font-semibold text-black;

    @screen lg {
      @apply text-2xl;
    }
  }

  code,
  pre {
    @apply bg-gray-200 px-4 py-2 rounded font-mono text-sm;
  }

  blockquote {
    @apply border-l-4 border-gray-300 pl-4;
  }

  a {
    @apply text-primary underline;

    &:hover {
      @apply opacity-75;
    }
  }

  ol {
    @apply list-decimal ml-4;
  }

  ul {
    @apply list-none flex -mb-6 flex-wrap;

    @screen sm {
      @apply -mx-4;
    }

    li {
      @apply mb-6 text-gray-700 flex items-start;

      @screen sm {
        @apply w-1/2 px-4;
      }

      svg {
        @apply text-primary text-xl mr-4 leading-none;
        margin-top: 4px;
      }

      &:before {
        @apply hidden;
        content: '\f14a';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
      }
    }
  }
}

.error_notification {
  @apply mb-4 bg-red-100 border-t-4 border-red-500 text-red-900 p-6;
}
