.autocomplete__menu {
  @apply w-full overflow-y-scroll absolute rounded mt-2 border border-gray-400;
  max-height: 300px;
  top: 100%;

  &--hidden {
    @apply hidden;
  }

  &--visible {
    @apply block;
  }

  .autocomplete__option {
    @apply bg-white p-2;

    &:hover {
      @apply text-primary;
    }

    &:focus,
    &:active {
      @apply outline-none border-2 border-black;
    }

    &--odd {
      @apply bg-gray-300;
    }
  }
}

.course-form {
  .autocomplete__wrapper {
    .autocomplete__input {
      @apply border border-gray-700 bg-white rounded appearance-none leading-none p-4 text-sm w-full;

      &:hover {
        @apply border-primary;
      }
    }
  }
}

.home-form {
  .search-wrapper {
    @apply flex w-full;
  }

  .autocomplete__wrapper {
    @apply flex w-full;

    .autocomplete__input {
      @apply bg-white rounded-l appearance-none leading-none p-4 text-sm w-full;

      &:hover {
        @apply bg-gray-200;
      }
    }
  }
}

.autocomplete-results {
  @apply mt-2 absolute max-h-64 overflow-y-scroll shadow-xl rounded;
}

.autocomplete-nav {
  @apply bg-white border border-gray-200 rounded;
}

.autocomplete-link {
  @apply w-full p-3 relative text-left text-sm;

  &:focus {
    @apply outline-none z-10 shadow-outline text-primary bg-gray-100;
  }

  &:hover {
    @apply text-primary bg-gray-100;
  }

  &:not(:first-of-type) {
    @apply border-t border-gray-200;
  }

  &:last-of-type {
    @apply rounded-b;
  }

  small {
    @apply block text-xs text-gray-800 mt-1;
  }
}

.autocomplete-input {
  @apply border-r-0 rounded-r-none;

  &:focus {
    @apply outline-none shadow-outline bg-gray-100;
    outline-offset: 0;
  }
}

.autocomplete-button {
  @apply appearance-none bg-primary text-white py-4 px-6 rounded-r;

  &:hover {
    @apply bg-primary-dark;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
