.form-label {
  @apply block text-gray-700 text-sm font-semibold mb-2;
}

.form-input {
  @apply bg-white appearance-none border border-gray-700 rounded w-full p-4 leading-tight;

  &:hover {
    @apply border-gray-900 shadow-md;
  }

  &--readonly {
    @apply bg-gray-200 cursor-not-allowed;
  }
}

.form-input-wrapper {
  @apply mb-4;

  .error {
    @apply block text-sm text-red-700 mt-2;
  }
}

.form-checkbox-label {
  @apply text-gray-700 text-sm font-semibold;
}

.form-checkbox-input {
  @apply leading-tight mr-2;
}

.form-side-block {
  @apply w-1/2 h-auto bg-primary bg-cover bg-no-repeat bg-center hidden;

  @screen lg {
    @apply block;
  }
}

.form-input-categories {
  @apply -mb-4;

  .checkbox {
    @apply inline-block mr-4 mb-4;

    input {
      @apply mr-2;
    }

    label {
      @apply border-2 px-4 py-2 rounded flex items-center;

      &:hover {
        @apply border-gray-900 shadow-md;
      }
    }
  }
}

.form-input-switch {
  @apply relative w-16 h-8 mb-4;

  .form-input-wrapper {
    @apply absolute inset-auto w-full h-full mb-0;
  }
}

.form-input-switch-input {
  @apply hidden;
}

.form-label-switch {
  @apply absolute inset-auto w-full h-full bg-gray-200 rounded-full;
  font-size: 0;
}

.form-input-switch-status {
  @apply rounded-full w-6 h-6 absolute bg-gray-500 pointer-events-none ml-2;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.25s ease;

  &[data-css-class='true'] {
    @apply bg-green-500 -ml-8;
    left: 100%;
  }

  &[data-css-class='false'] {
    @apply bg-gray-500 ml-2;
    left: 0;
  }
}

input:not(:placeholder-shown) + .form-search-icon,
input:not(:placeholder-shown):valid:focus + .form-search-icon,
input:not(:placeholder-shown):valid:hover + .form-search-icon {
  display: none;
}

input:not(:placeholder-shown):valid + .form-search-icon {
  display: block;
}

.field_with_errors {
  .form-input {
    @apply border-red-700;
  }
}

.form-search-icon {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
