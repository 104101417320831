.review-card {
  @apply border border-gray-300 bg-white px-6 py-4 justify-between flex flex-col rounded;

  @screen lg {
    @apply px-12 py-8;
  }

  &__quote {
    @apply mb-4;

    @screen lg {
      @apply mb-8;
    }
  }

  &__author {
    @apply flex items-center;
  }
}

.review-author {
  &__avatar {
    @apply w-10 h-10 rounded-full mr-2;
  }

  &__name {
    @apply text-gray-800 font-semibold uppercase;
  }

  &__company {
    @apply text-gray-700 text-sm uppercase;
  }
}
