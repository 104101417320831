.subtitle {
  @apply text-sm mb-1 leading-none uppercase;
}

.subtitle-primary {
  @apply text-primary;
}

.subtitle-white {
  @apply text-white;
}
