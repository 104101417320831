.course-card {
  @apply bg-white overflow-hidden border-b-4 border-primary flex justify-end flex-col h-full;

  &:hover {
    @apply shadow-lg;
  }

  &__subheading {
    @apply text-primary text-xs mb-1 leading-none font-semibold;
  }

  &__heading {
    @apply font-semibold leading-tight;

    @screen lg {
      @apply text-xl;
    }
  }

  &__date {
    @apply text-sm flex items-center mt-2;
  }
}

.course-date {
  &__icon {
    @apply opacity-75 mr-2;
  }

  &__text {
    @apply leading-none;
  }
}

.course-slider {
  @apply relative;

  &:before {
    @apply h-16 -mt-16 absolute w-full top-0 left-0;
    background: linear-gradient(
      to top,
      rgba($black, 0.85) 0%,
      transparent 100%
    );
    content: '';
  }

  &__controls {
    top: 50%;
    transform: translateY(-50%);
  }
}

.course-list-link {
  @apply pb-4 font-semibold border-b border-black text-black relative pr-6 flex justify-between;

  &:hover {
    @apply border-primary text-primary;
  }
}
